// Here you can add other styles

@media print {
    .dealer-table-head {
        span {display: none;}
    }
}

.black-placeholder {
    ::placeholder {
        color: $input-color;
        opacity: 1;
    }
}

.form-multi-select-search {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='gray' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
    background-repeat: no-repeat;
    background-position: top right 10px;
}

.fc-toolbar-title, .fc-col-header-cell-cushion  {
    text-transform: capitalize;
}

.fc-event-time, .fc-event-title {
    padding: 0 1px;
    white-space: normal;
    }